<template>
  <div id="home">
    <van-nav-bar
        title="余额充值"
        :left-arrow="true"
        @click-left="goback('/recharge')"
        fixed
    />
    <div class="login wallets">
      <van-field v-model="surplus_recharge"
                 label-width="138px"
                 label="当前余额:" readonly clearable input-align="right"/>
      <van-field class="rechargeprice"
          label-width="138px"
          label="充值金额:" readonly clearable/>
      <div class="sdiv van-cell">
        <template v-for="(slamunt,aidx) in amoutlist" :key="aidx">
        <span @click.stop="chooseAmount(slamunt)" :class="parseInt(slamunt.price) == parseInt(selected_amount) ? 'current' : ''">¥ {{slamunt.price}}</span>
        </template>
      </div>
      <van-field v-model="recharge.giftprice"
          label-width="138px"
          label="充值赠送:" readonly clearable input-align="right"/>
      <van-field v-model="recharge.totalprice"
          label-width="138px"
          label="获得金额:" readonly clearable input-align="right"/>
      <div class="van-cell">
        <van-button type="danger"
                    class="bottombtn"
                    block
                    :loading="loading"
                    :disabled="loading"
                    @click.stop="toPay">
          立即充值
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import {httpget,httppost} from "@/libs/api";
import {Dialog,Toast} from "vant";
import {useRoute, useRouter} from "vue-router";

export default {
  name: 'recharge',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      loading:false,
      surplus_recharge:'0.00',
      selected_amount: 0.00,
      selected_id:0,
      code:'',
      scope:'',
      pay_amount: '',
      searchinfo:{
        page:0,
      },
      appid:'',
      amoutlist:[],
      recharge:{
        giftprice:'0.00',
        totalprice:'0.00'
      },
    })
    const chooseAmount = (slamunt) =>{
      state.selected_amount = slamunt.price;
      state.selected_id = slamunt.id;
      state.recharge.giftprice = slamunt.giftprice;
      let totalprice = parseFloat(slamunt.giftprice)+parseFloat(slamunt.price);
      state.recharge.totalprice = totalprice.toFixed(2);
    }
    const toPay = () => {
      if( parseFloat(state.selected_amount) <= 0 ){
        Toast.fail('请选择充值金额');
        return false;
      }
      Dialog.confirm({
        title: '充值提示',
        message: '确认充值'+state.selected_amount+'？',
      })
      .then( async () => {
        state.loading = true;
        let param = {
          sid:state.selected_id,
          amount:state.selected_amount,
          giftamount:state.recharge.giftprice,
          totalamount:state.recharge.totalprice,
        };
        let res = await httppost('/api/recharge/order',param);
        if(res.code===0){
          state.loading = false;
          onBridgeReady(res.data);
        } else if( res.code == 2 ){
          state.appid = res.data.appid;
          state.scope = res.data.scope;
          getCode();
        }else{
          state.loading = false;
          state.finished = true;
          return ;
        }
      })
      .catch(() => {
        console.log('fail');
      });
    }
    const onBridgeReady = (resJson)=>{
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', resJson,
        function(res){
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            router.push({path: '/recharge'}).catch(err => {err});
          }
        });
    }
    const getlist = async () =>{
      state.loading = true;
      let res = await httpget('/api/recharge/amountlist');
      if(res.code===0){
        state.loading = false;
        state.amoutlist = res.data.list;
        state.surplus_recharge = res.data.surplus_recharge;
      }else{
        state.loading = false;
        state.finished = true;
        return ;
      }
    }
    const getCode = () => {
      let local = window.location.href;
      if (state.code == null || state.code === '') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + state.appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope='+state.scope+'&state=1#wechat_redirect'
      }
    }
    const getOpenId = async () =>  {
      let res = await httppost('/api/auth/login', { code: state.code });
      if(res.code===0){
        console.log('success');
      }else{
        console.log('fail');
      }
    }
    onMounted(() => {
      let code = route.query.code? route.query.code : '';
      state.code = code;
      getlist();
      if( state.code ){
        getOpenId()
      }
    })

    return {
      ...toRefs(state),
      getlist,
      onBridgeReady,
      getCode,
      getOpenId,
      chooseAmount,
      toPay
    };
  },
}
</script>
